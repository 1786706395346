import { createContext, ReactElement, useContext, useState } from 'react';
import { useSearchPage } from 'components/SearchPage/hooks/useSearchPage';
import type { ISearchPageDispatch } from 'components/SearchPage/hooks/useSearchPage';
import type {
  ISearchPageAds,
  SearchPageBreadcrumbs,
  ISearchPagePaging,
  ISearchPageSeoData,
  IDFP,
  Ad,
} from 'api/types/searchPageApiTypes';
import type { ViewType } from 'components/SearchPage/SearchPage.typed';
import type { GTMEvent } from 'services/gtmService';
import type { Faq } from '../components/Faq/Faq.typed';

export type SearchPageStateType = {
  baseUrl: string;
  viewType: ViewType;
  paging: ISearchPagePaging;
  saved: boolean;
  breadcrumbs: Array<SearchPageBreadcrumbs>;
  recommendations?: Array<ISearchPageAds>;
  ads?: Array<Ad> | null;
  spotlights?: Array<Ad> | null;
  seoData: ISearchPageSeoData;
  dfp: IDFP;
  gtm: GTMEvent;
  faq?: Faq;
  isLoading: boolean;
};

type SearchPageProviderProps = {
  baseUrl: string;
  paging: ISearchPagePaging;
  viewType: ViewType;
  saved: boolean;
  initialBreadcrumbs: SearchPageBreadcrumbs[];
  initialRecommendations?: Array<ISearchPageAds>;
  seoData: ISearchPageSeoData;
  dfp: IDFP;
  gtm: GTMEvent;
  ads: Array<Ad> | null;
  spotlights: Array<Ad> | null;
  faq?: Faq | null;
  children: ReactElement;
};

export type SearchPageDispatchType = ISearchPageDispatch & {
  setViewType: (viewType: ViewType) => void;
};

export const SearchPageDispatchContext = createContext(
  {} as SearchPageDispatchType,
);

export const SearchPageStateContext = createContext({} as SearchPageStateType);

export const useSearchPageDispatch = (): SearchPageDispatchType =>
  useContext(SearchPageDispatchContext);
export const useSearchPageState = (): SearchPageStateType =>
  useContext(SearchPageStateContext);

export const SearchPageProvider = ({
  baseUrl,
  paging,
  viewType,
  saved,
  initialBreadcrumbs,
  initialRecommendations = [],
  seoData,
  dfp,
  gtm,
  ads,
  spotlights,
  faq,
  children,
}: SearchPageProviderProps): ReactElement => {
  const [viewTypeState, setViewType] = useState<ViewType>(viewType);
  const { state, dispatch } = useSearchPage(
    initialBreadcrumbs,
    initialRecommendations,
    seoData,
    dfp,
    gtm,
    paging,
    ads,
    spotlights,
    faq,
  );

  return (
    <SearchPageDispatchContext.Provider
      value={{
        setViewType,
        ...dispatch,
      }}
    >
      <SearchPageStateContext.Provider
        value={{
          baseUrl,
          saved,
          viewType: viewTypeState,
          ...state,
        }}
      >
        {children}
      </SearchPageStateContext.Provider>
    </SearchPageDispatchContext.Provider>
  );
};
