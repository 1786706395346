import { useState } from 'react';

import { searchPageApi } from 'api/searchPageApi';

import { fireToast } from 'helpers/Toasts';

import type { IGetAdsRequest, IGetSection } from 'api/types/searchPageApiTypes';
import type { Section } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.typed';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { API_CLIENT_TIMEOUT } from 'utils';

export type UseSectionDisplayInformationArgs = {
  initialSectionName: string;
  initialSections: IGetSection[];
  initialSectionDisplayName: string;
  initialParentName: string | null;
  initialParentDisplayName: string | null;
  initialSearchMatches: number;
};

type SectionData = {
  sectionName: string;
  sections?: Section[];
  sectionDisplayName: string;
  parentName: string | null;
  parentDisplayName: string | null;
  searchMatches: number;
};

function useSectionDisplayInformation(props: UseSectionDisplayInformationArgs) {
  const {
    initialSectionName,
    initialSections,
    initialSectionDisplayName,
    initialParentName,
    initialParentDisplayName,
    initialSearchMatches,
  } = props;

  const [sectionData, setSectionData] = useState<SectionData>({
    sectionName: initialSectionName,
    sections: initialSections,
    sectionDisplayName: initialSectionDisplayName,
    parentName: initialParentName,
    parentDisplayName: initialParentDisplayName,
    searchMatches: initialSearchMatches,
  });

  async function retrieveSections(
    sectionName: string,
    payload: IGetAdsRequest,
  ) {
    try {
      const { data } = await searchPageApi.getSections(
        sectionName,
        payload,
        API_CLIENT_TIMEOUT,
      );
      setSectionData({
        sectionName,
        sections: data.subSections,
        sectionDisplayName: data.displayName,
        parentName: data.parentName ?? null,
        parentDisplayName: data.parentDisplayName ?? null,
        searchMatches: data.searchMatches ?? 0,
      });
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling retrieveSections'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
          section: sectionName,
          payload: payload,
        },
      });
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
    }
  }

  return {
    ...sectionData,
    retrieveSections,
  };
}

export { useSectionDisplayInformation };
