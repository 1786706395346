import { useEffect, useRef, useState, Fragment } from 'react';
import { useCombobox } from 'downshift';

import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type {
  MakeModel,
  Options,
} from 'components/SearchPage/features/makeModel/MakeModel.typed';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import {
  SearchableDropdown,
  highlight,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { useMakeModelContext } from '../MakeModelContext';

type MakeFilterProps = {
  value: string;
  heightVariant?: HeightVariant;
  options: Options[];
  // If shown, selecting the label will open dropdown. If false, dropdown will be permanently open.
  showLabel?: boolean;
  maxHeight?: string;
  addMake: (item: ListItem) => void;
};

// downshift expects a flat array of items, so we need to flatten the options
const flattenMakeOptions = (
  options: Options[],
  // if true, groups will not exist. Needed as while searching we don't want to show group headings
  noGroup = false,
): ListItem[] => {
  // TODO: Follow up task to unify this in MakeModel.map.ts
  return options.flatMap(({ title, items }) =>
    items.map(({ displayName, value, isDisabled }, index) => ({
      value,
      displayName,
      isDisabled,
      group: noGroup ? undefined : (index === 0 && title) || undefined,
    })),
  );
};

const MakeFilter = (props: MakeFilterProps) => {
  const {
    value: makeValue,
    heightVariant,
    options: mappedMakeOptions,
    showLabel = false,
    addMake,
    maxHeight,
  } = props;

  const { makeModels } = useMakeModelContext();
  const makeModelsRef = useRef(makeModels);

  /**
   * This effect ensures the latest make filter is focused when:
   * - A new make is added
   * - A make/model is removed
   */
  useEffect(() => {
    const countEditable = (acc: number, makeModel: MakeModel) =>
      makeModel.isEditable ? acc + 1 : acc;
    const lengthChanged = makeModelsRef.current?.length !== makeModels?.length;
    const addedMake =
      makeModelsRef.current?.reduce(countEditable, 0) !==
      makeModels?.reduce(countEditable, 0);
    if (lengthChanged || addedMake) {
      toggleButtonRef.current?.focus();
      makeModelsRef.current = makeModels;
    }
  }, [makeModels]);

  // This list is used when initially displaying the options (with groups)
  const options = flattenMakeOptions(mappedMakeOptions);
  // Once a user starts typing, we only filter the 'All Makes' group
  const justAllMakes = flattenMakeOptions(
    mappedMakeOptions.filter((option) => option.title === 'All Makes'),
    // Group heading is not shown when searching
    true,
  );

  const [items, setItems] = useState(options);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const resetItems = () => setItems(options);

  const {
    isOpen,
    inputValue: value,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items,
    // Triggered when user types in the input
    onInputValueChange: ({ inputValue }) => {
      // Ensures when input is cleared the original options with groups are shown
      if (!inputValue) resetItems();
      else {
        setItems(
          justAllMakes.filter((item) =>
            item.displayName.toLowerCase().startsWith(inputValue.toLowerCase()),
          ),
        );
      }
    },
    // Triggered when user selects an item
    onSelectedItemChange: ({ selectedItem }) => addMake(selectedItem),
    // Tells downshift how to get the display value of an item, as each item is an object
    itemToString: (item) => (item ? item.displayName : ''),
    // Triggered when the dropdown is opened or closed
    onIsOpenChange: ({ isOpen }) => {
      // Clear input when dropdown is closed
      if (!isOpen) setInputValue('');
    },
    // Determines if an item is disabled, required for skipping over items when navigating with keyboard
    isItemDisabled: (item) => isDisabled(item),
    // This is necessary to ensure the highlighted item is always in view
    scrollIntoView: (node) => node?.scrollIntoView({ block: 'nearest' }),
    // For a given action, we can modify the state changes
    stateReducer(_state, actionAndChanges) {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // Without this, the dropdown would close when the searchbox is clicked.
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true,
          };
        // Without this, the focus is lost when an item is selected/dropdown is closed.
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          toggleButtonRef.current?.focus();
          return changes;
        default:
          return changes;
      }
    },
  });

  const isSelected = (item: ListItem) => item.value === makeValue;
  const isDisabled = (item: ListItem) => {
    if (isSelected(item)) return false;
    const option = options.find((option) => option.value === item.value);
    return option?.isDisabled ?? false;
  };

  return (
    <>
      {showLabel && (
        <InputButton
          value={makeValue}
          heightVariant={heightVariant}
          isActive={isOpen}
          dataTestId="desktop-make-input-button"
          {...getToggleButtonProps({
            placeholder: 'Makes',
            // Passing ref from outside allows for programmatic focusing
            ref: toggleButtonRef,
            // tabIndex is needed to allow the button to be focusable, defaults to "-1"
            tabIndex: 0,
          })}
        />
      )}
      <SearchableDropdown isOpen={!showLabel || isOpen}>
        <label {...getLabelProps()}>
          <SearchableDropdown.Searchbox
            placeholder="Search any make"
            onClear={() => setInputValue('')}
            {...getInputProps()}
            data-testid="make-dropdown-input"
          />
        </label>
        <SearchableDropdown.List
          dataTestId="make-dropdown-list"
          noResults={items.length === 0}
          maxHeight={maxHeight}
          {...getMenuProps()}
        >
          {items.map((make, index) => (
            <Fragment key={`${make.value}-${index}`}>
              {make.group && (
                <SearchableDropdown.ListGroup>
                  {make.group}
                </SearchableDropdown.ListGroup>
              )}
              <SearchableDropdown.ListItem
                disabled={isDisabled(make)}
                data-testid={`make-dropdown-listitem-${index}`}
                highlighted={highlightedIndex === index}
                {...getItemProps({
                  'aria-disabled': isDisabled(make),
                  item: make,
                  index,
                })}
              >
                {highlight({
                  item: make.displayName,
                  searchText: value,
                  selectedItem: makeValue,
                  disabled: isDisabled(make),
                })}
              </SearchableDropdown.ListItem>
            </Fragment>
          ))}
        </SearchableDropdown.List>
      </SearchableDropdown>
    </>
  );
};

export { MakeFilter };
