import { areasRepository } from 'repositories/Area/AreasRepository';

import { isOk } from 'domains/Result';
import { separateValues } from 'utils/CombineAndSeparateValues';

import type { ParsedUrlQuery } from 'querystring';

import type {
  GeoFilter,
  IGetAdsRequest,
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
  ISearchPageFilter,
  ISearchPageFilterValue,
} from 'api/types/searchPageApiTypes';
import type { ResponseMakeModelType, UrlParamType } from 'types';
import type { DropDownValue } from 'features/filters/components/PriceSelectRange/PriceSelectRange';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';

import { provinces } from 'helpers/constants';
import { DEFAULT_COORDINATES } from 'features/location/Location.constants';

import { CDN_STATIC_ASSETS } from 'utils';
import {
  formatPathParameters,
  generateGetAdsRequestParams,
  getSectionParams,
} from 'helpers/seo/searchParams';
import { asString } from 'utils/query-parameters';
import { findCountyByValue } from 'features/location/helpers';
import { areasApi } from 'api/areasApi';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';

const mapRangesRequest = (ranges: IGetAdsRequestRange[]) =>
  ranges.reduce<IGetAdsRequestRange[]>((items, range) => {
    // remove empty ranges
    if (typeof range.to === 'undefined' && typeof range.from === 'undefined') {
      return items;
    }
    if (range.name === 'price') {
      const item = { ...range };
      const currency = item.currency;
      const typePrice = item.typePrice;
      delete item.currency;
      delete item.typePrice;
      items.push({
        ...item,
        name:
          currency === 'GBP'
            ? 'sterlingPrice'
            : typePrice === 'pricePerMonth'
            ? 'pricePerMonth'
            : 'price',
      });
    } else {
      items.push(range);
    }
    return items;
  }, []);

const mapFiltersRequest = (filters: IGetAdsRequestFilter[]) =>
  filters.reduce<IGetAdsRequestFilter[]>((items, filter) => {
    // Remove empty filters
    if (!filter.values.length) {
      return items;
    }
    if (filter.values.length === 1 && typeof filter.values[0] === 'undefined') {
      return items;
    }
    items.push(filter);
    return items;
  }, []);

const mapOptionsRanges = (from: DropDownValue, to: DropDownValue) => {
  if (from.selectedValue) {
    return [
      to.options[0],
      ...(to.options &&
        to.options.filter((item) => {
          return (
            item.label !== 'Max' &&
            Number(from.selectedValue) <= Number(item.value)
          );
        })),
    ];
  } else return to.options;
};

const mapToMultiSelect = (values?: ISearchPageFilterValue[]) =>
  values
    ? values.map((item) => ({
        value: item.value ?? '',
        label: item.displayName ?? '',
        count: item.count ?? '',
      }))
    : [];

const mapNameToImgSrc = (
  filterName: string,
  sectionName: string,
  optionValue?: string,
) => {
  if (!optionValue || optionValue === 'Other') return;
  let name = `${filterName}-${optionValue}`.replace(/ /g, '-').toLowerCase();

  // Specific requirement for vans in Commercials section - both cars section and commercials have body type 'van' but we need to show a different image for van in commercials
  if (optionValue === 'Van' && sectionName === 'Commercials') {
    name += '-commercials';
  }

  return `${CDN_STATIC_ASSETS}/images/filters/${name}.png`;
};

const mapToMultiSelectImage = (
  values: ISearchPageFilterValue[],
  filterName: string,
  sectionName: string,
) =>
  values.map((item) => ({
    value: item.value ?? '',
    label: item.displayName ?? '',
    imgSrc: mapNameToImgSrc(filterName, sectionName, item.value),
  }));

const mapSelectedOptionsToMultiSelect = (
  values: ISearchPageFilterValue[] | null,
) => (values ? values.map(({ value }) => value ?? '') : []);

const mapToSingleSelect = (values?: ISearchPageFilterValue[]) =>
  values
    ? values.map((item) => ({
        value: item.value ?? '',
        label: item.displayName ?? '',
        type: item.type,
      }))
    : [];

const mapToPriceSelect = (
  values?: ISearchPageFilterValue[],
  currency?: string,
) =>
  values
    ? values.map((item) => ({
        value: item.value ?? '',
        label:
          item.value !== ''
            ? `${currency}${item.displayName}`
            : item.displayName ?? '',
      }))
    : [];

const createHiddenFilters = (): ISearchPageFilter[] => {
  const HIDDEN_FILTERS = [
    'financeProvider',
    'cashOffer',
    'dealerId',
    'carFinance',
  ];
  return HIDDEN_FILTERS.map((key) => {
    return {
      filterType: { id: 0, name: 'hidden' },
      displayHint: '',
      id: 0,
      name: key,
      displayName: key,
      maxLength: 0,
      searchQueryGroup: 'filters',
      values: [],
      variant: 'HIDDEN',
    };
  });
};

interface MapGeoFilterProps {
  radius?: string | null;
  latitude?: string;
  longitude?: string;
  county: string | string[] | null;
  countyTown: string | null;
}

const mapGeoFilter = ({
  radius,
  latitude,
  longitude,
  county,
  countyTown,
}: MapGeoFilterProps) => {
  const formattedCounty = Array.isArray(county) ? county[0] : county;
  if (latitude && longitude && radius) {
    return {
      geoFilter: {
        lat: parseFloat(latitude),
        lon: parseFloat(longitude),
        rad: parseInt(radius),
        countyTown,
        county: formattedCounty,
      },
    };
  } else if (county) {
    return {
      geoFilter: {
        lat: 0,
        lon: 0,
        rad: 0,
        county: formattedCounty,
        countyTown: null,
      },
    };
  } else return null;
};

// For server-rendering
const mapCountyList = (
  countyParam: string[] | null,
  countyList: ListItem[],
) => {
  return countyParam &&
    countyParam.length === 1 &&
    !provinces.includes(countyParam[0])
    ? countyList.map((county) => {
        const formattedDisplayName =
          county.value.toLowerCase() === countyParam[0].toLowerCase()
            ? `<b>${county.displayName}</b>`
            : null;
        return {
          ...county,
          formattedDisplayName,
        };
      })
    : [
        {
          ...countyList[0],
          formattedDisplayName: `<b>${countyList[0].displayName}</b>`,
        },
        ...countyList.slice(1),
      ];
};

export const mapLocationDisplay = (countyParam: string[] | null) => {
  const multipleChoicesText =
    countyParam && countyParam.length > 1
      ? ` (+${countyParam.length - 1})`
      : '';

  // Allow for multiple areas or search by province (legacy functionality)
  return countyParam &&
    (countyParam.length > 1 || provinces.includes(countyParam[0]))
    ? `${countyParam[0]}${multipleChoicesText}`
    : null;
};

interface IMapSelectedCounyAndCoordinatesAndLocationDisplay {
  sectionQueryParams: {
    [filter: string]: UrlParamType;
  };
  counties: {
    displayName: string;
    value: string;
  }[];
  countyParam: string[] | null;
  countyTownParam: string | null;
}
const mapSelectedCounyAndCoordinatesAndLocationDisplay = async ({
  sectionQueryParams,
  counties,
  countyParam,
  countyTownParam,
}: IMapSelectedCounyAndCoordinatesAndLocationDisplay) => {
  const [selectedCounty] = sectionQueryParams.area
    ? counties.filter(
        (county: { displayName: string; value: string }) =>
          county.value === sectionQueryParams.area,
      )
    : countyParam
    ? counties.filter(
        (county: { displayName: string; value: string }) =>
          county.value.toLowerCase() === countyParam[0].toLowerCase(),
      )
    : [];

  let coordinates = DEFAULT_COORDINATES;
  let locationDisplay = selectedCounty ? selectedCounty.displayName : '';

  if (countyTownParam && selectedCounty) {
    const areas = await areasRepository.getCountyAreas(
      selectedCounty.value,
      selectedCounty.displayName,
    );
    if (isOk(areas)) {
      const [match] = areas.ok.filter(
        (area) =>
          area.displayName ===
          `${countyTownParam}, ${selectedCounty.displayName}`,
      );
      if (match && match.value) {
        const [latitude, longitude] = separateValues(match.value);
        coordinates = {
          latitude,
          longitude,
        };
        locationDisplay = `${countyTownParam}, ${selectedCounty.displayName}`;
      }
    }
  }

  return {
    selectedCounty,
    coordinates,
    locationDisplay,
  };
};

const mapGeoFilterRequest = (geoFilter?: GeoFilter) =>
  geoFilter?.countyTown !== null ? geoFilter : undefined;

const mapLegacyAreaFilterValues = (
  filterValues: IGetAdsRequestFilter[],
  counties?: string | null,
  countyTown?: string | null,
) => {
  const updatedFilterValues = [...filterValues];
  const includesAreaFilter =
    filterValues.filter((item) => item.name === 'area').length > 0;

  if (!includesAreaFilter && counties && !countyTown) {
    updatedFilterValues.push({
      name: 'area',
      values: [counties],
    });
  }

  return updatedFilterValues;
};

const mapLegacyAreaFilterQueryValues = (
  query: ParsedUrlQuery,
  counties?: string | null,
  countyTown?: string | null,
) => {
  const updatedFilterValues = { ...query };
  const includesAreaFilter = query.area;

  if (!includesAreaFilter && counties && !countyTown) {
    updatedFilterValues.area = [counties];
  }

  return updatedFilterValues;
};

const groupByMake = (arrayObjects: any[], key: string) => {
  return arrayObjects.reduce((result, currentObject) => {
    const val = currentObject[key];
    result[val] = result[val] || [];
    currentObject.model && result[val].push(currentObject.model);
    return result;
  }, {});
};

const groupModelsByMake = (makeModelParams: ResponseMakeModelType) =>
  groupByMake(mapMakeModel(makeModelParams), 'make');

const mapMakeModel = (makeModelParams: ResponseMakeModelType) => {
  return makeModelParams
    ? makeModelParams.map((makeModelItem) => ({
        ...makeModelItem,
      }))
    : [];
};

const mapGetAdsRequestPayload = (args: {
  query: ParsedUrlQuery;
  filtersData: Array<ISearchPageFilter>;
}) => {
  const { query, filtersData } = args;

  const { isMakeModel, makeModelParams, sectionQueryParams, countyList } =
    getSectionParams(filtersData, query);

  const {
    section,
    countyTown: countyTownQueryValue,
    area: areaQueryValue,
    radius: radiusQueryValue,
    latitude: latitudeQueryValue,
    longitude: longitudeQueryValue,
    userId,
  } = query;

  const { currentSection = 'all', SEOFilterValue } =
    formatPathParameters(section);

  const carFilterQueryValue = asString(SEOFilterValue);

  // Determine if carFilterQueryValue is a valid county value
  const countyPathParam = findCountyByValue(countyList, carFilterQueryValue);
  const county = countyPathParam?.value ?? areaQueryValue ?? null;
  const countyTown = asString(countyTownQueryValue) ?? null;
  const radius = asString(radiusQueryValue);
  const latitude = asString(latitudeQueryValue) ?? '';
  const longitude = asString(longitudeQueryValue) ?? '';

  const locationData = mapGeoFilter({
    radius,
    latitude,
    longitude,
    county,
    countyTown,
  });

  const updatedQueryValues = mapLegacyAreaFilterQueryValues(
    query,
    locationData?.geoFilter?.county,
    locationData?.geoFilter?.countyTown,
  );

  const getAdsRequestParams = generateGetAdsRequestParams(
    currentSection,
    {
      ...updatedQueryValues,
      ...sectionQueryParams,
    },
    filtersData,
    userId,
  );

  const getAdsPayload: IGetAdsRequest = {
    geoFilter: mapGeoFilterRequest(locationData?.geoFilter),
    ...getAdsRequestParams,
    ...(getAdsRequestParams.ranges
      ? { ranges: mapRangesRequest(getAdsRequestParams.ranges) }
      : {}),
    ...(isMakeModel && {
      makeModelFilters: makeModelParams,
    }),
  };

  return getAdsPayload;
};

const findCoordinates = async (args: {
  county: string;
  countyTown: string;
}) => {
  const { county, countyTown } = args;

  try {
    const { data } = await areasApi.getAreas(county);
    const selectedCounty = data.find((item) => item.countyTown === countyTown);
    return {
      latitude: asString(selectedCounty?.latitude),
      longitude: asString(selectedCounty?.longitude),
    };
  } catch (error) {
    rg4js('send', {
      error: new Error('Error requesting county towns'),
      tags: [PAGE.SEARCH],
      customData: {
        message: error.message || 'client_error',
      },
    });
    return null;
  }
};

export {
  mapRangesRequest,
  mapFiltersRequest,
  mapOptionsRanges,
  mapToMultiSelect,
  mapToMultiSelectImage,
  mapSelectedOptionsToMultiSelect,
  mapToSingleSelect,
  mapToPriceSelect,
  mapGeoFilter,
  mapCountyList,
  mapSelectedCounyAndCoordinatesAndLocationDisplay,
  mapGeoFilterRequest,
  mapLegacyAreaFilterValues,
  createHiddenFilters,
  mapLegacyAreaFilterQueryValues,
  mapGetAdsRequestPayload,
  findCoordinates,
  groupModelsByMake,
};
