import { useRouter } from 'next/router';

import { searchPageApi } from 'api/searchPageApi';

import {
  useSearchPageDispatch,
  useSearchPageState,
} from 'components/SearchPage/context/SearchPageContext';
import {
  useFiltersDispatch,
  useFiltersState,
} from 'features/filters/Filters.context';

import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { API_CLIENT_TIMEOUT } from 'utils';
import { fireToast } from 'helpers/Toasts';
import {
  createHiddenFilters,
  findCoordinates,
  mapGetAdsRequestPayload,
} from 'features/filters/Filters.mapper';
import { asString } from 'utils/query-parameters';
import { findCountyByValue } from 'features/location/helpers';
import {
  formatPathParameters,
  getSectionParams,
  formatFaqSchema,
} from 'helpers/seo/searchParams';
import { useURLStateManagement } from '../useURLStateManagement/useURLStateManagement';
import { useSearchPageGTM } from '../useSearchPageGTM';
import {
  type UseSectionDisplayInformationArgs,
  useSectionDisplayInformation,
} from '../useSectionDisplayInformation';

type useAdsArgs = Omit<UseSectionDisplayInformationArgs, 'initialSectionName'>;

const useAds = (args: useAdsArgs) => {
  const { query } = useRouter();
  const {
    countyTown: countyTownQueryValue,
    latitude: latitudeQueryValue,
    longitude: longitudeQueryValue,
    area: areaQueryValue,
    start: paginationQueryValue,
    section,
  } = query;
  const latitude = asString(latitudeQueryValue) ?? null;
  const longitude = asString(longitudeQueryValue) ?? null;
  const countyTown = asString(countyTownQueryValue) ?? null;
  const start = asString(paginationQueryValue) ?? null;

  const { filtersData } = useFiltersState();
  const { setFiltersData, setSortFields } = useFiltersDispatch();

  const { setAds, setIsLoading } = useSearchPageDispatch();

  const { viewType, gtm: gtmData } = useSearchPageState();

  const { updateURL } = useURLStateManagement();

  const getAdsPayload = mapGetAdsRequestPayload({ query, filtersData });

  const { currentSection = 'all' } = formatPathParameters(section);

  const { searchPageEvent } = useSearchPageGTM(gtmData);

  const {
    sectionName,
    retrieveSections,
    sections,
    sectionDisplayName,
    parentName,
    parentDisplayName,
    searchMatches,
  } = useSectionDisplayInformation({
    ...args,
    initialSectionName: currentSection,
  });

  const getAdsSectionsAndFilters = async () => {
    setIsLoading(true);

    const shouldFetchSectionsData =
      (sections && sections?.length > 0) || currentSection !== sectionName;

    const missingCoordinates = Boolean(countyTown && (!latitude || !longitude));

    /*
     * Latitude and longitude are hidden query values that may not be available on page load.
     * If there is a county town but no coordinates, we search for the corresponding coordinates
     * then update the URL, allowing getAds to be called again with all the necessary payload values.
     */
    if (missingCoordinates) {
      const coordinates = await mapCoordinates();

      if (coordinates) {
        /*
         * We do not end the loading state as we should transition immediately into a new ads request once the URL is updated,
         * avoiding the stale ads being redisplayed and a new loading state being applied.
         */
        return updateURL({
          /*
           * When the URL is updated, the pagination value is reset in anticipation of a new set of ads based on the filter values provided.
           * Here we want to update the URL to add missing values rather than conduct a new search,
           * which is why we explicitly maintain the pagination value .
           */
          queryParams: {
            start: start ?? '',
          },
          hiddenQueryParams: {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          },
        });
      }
    }
    handleAdsRequest();
    handleFiltersRequest();
    if (shouldFetchSectionsData) {
      await retrieveSections(currentSection, getAdsPayload);
    }
  };

  const mapCoordinates = async () => {
    const { SEOFilterValue } = formatPathParameters(section);

    const carFilterQueryValue = asString(SEOFilterValue);

    const { countyList } = getSectionParams(filtersData, query);

    const countyPathParam = findCountyByValue(countyList, carFilterQueryValue);
    const county = asString(countyPathParam?.value ?? areaQueryValue);

    if (county && countyTown) {
      const coordinates = await findCoordinates({
        county,
        countyTown,
      });

      if (coordinates?.latitude && coordinates?.longitude) {
        return {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        };
      }
      return null;
    }
    return null;
  };

  const handleAdsRequest = async () => {
    try {
      const {
        data: {
          ads,
          spotlights,
          paging,
          seoData,
          dataTargetingProps: { dfp, gtm },
          breadcrumbs,
          recommendations,
          seoFaqSchema,
        },
      } = await searchPageApi.getAdsV2(
        getAdsPayload,
        undefined,
        API_CLIENT_TIMEOUT,
      );

      const faq =
        seoFaqSchema?.faqSchema && Array.isArray(section)
          ? formatFaqSchema(seoFaqSchema?.faqSchema, section)
          : null;

      setAds({
        ads,
        spotlights,
        seoData,
        dfp,
        gtm,
        breadcrumbs,
        recommendations,
        paging,
        faq,
      });

      searchPageEvent({ viewType: [viewType] });
    } catch (error) {
      handleError('Error handling getAds', {
        message: error.message || 'client_error',
        payload: getAdsPayload,
      });
      setIsLoading(false);
    }
  };

  const handleFiltersRequest = async () => {
    try {
      const { data } = await searchPageApi.getFiltersWithCount({
        section: currentSection,
        payload: getAdsPayload,
        timeout: API_CLIENT_TIMEOUT,
      });

      const combinedfiltersData = [
        ...data.showByDefault,
        ...createHiddenFilters(),
      ];
      setFiltersData(combinedfiltersData);
      setSortFields(data.sortFields);
    } catch (error) {
      handleError('Error handling retrieveFilters', {
        message: error.message || 'client_error',
        query,
        currentSectionQueryValue: currentSection,
      });
    }
  };

  const handleError = (
    errorText: string,
    customData: { [key: string]: any },
  ) => {
    rg4js('send', {
      error: new Error(errorText),
      tags: [PAGE.SEARCH],
      customData,
    });
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later',
    });
  };

  return {
    getAdsSectionsAndFilters,
    sections,
    sectionDisplayName,
    parentName,
    parentDisplayName,
    searchMatches,
  };
};

export { useAds };
