import { useReducer } from 'react';

import type {
  ISearchPageAds,
  SearchPageBreadcrumbs,
  ISearchPagePaging,
  IDFP,
  ISearchPageSeoData,
  Ad,
} from 'api/types/searchPageApiTypes';
import type { GTMEvent } from 'services/gtmService';
import type { Faq } from '../components/Faq/Faq.typed';

enum ACTION_TYPE {
  SET_ADS = 'SET_ADS',
  SET_IS_LOADING = 'IS_LOADING',
}

type PagingType = ISearchPagePaging;
type SetAdsArgs = {
  ads?: Array<Ad>;
  spotlights?: Array<Ad>;
  seoData?: ISearchPageSeoData;
  dfp: IDFP;
  gtm: GTMEvent;
  breadcrumbs: Array<SearchPageBreadcrumbs>;
  recommendations?: Array<ISearchPageAds>;
  paging?: ISearchPagePaging;
  faq?: Faq | null;
};

type SetAds = {
  type: ACTION_TYPE.SET_ADS;
  payload: SetAdsArgs;
};
type SetIsLoading = {
  type: ACTION_TYPE.SET_IS_LOADING;
  payload: boolean;
};

type SearchAction = SetAds | SetIsLoading;

function searchReducer(
  state: ISearchPageState,
  action: SearchAction,
): ISearchPageState {
  switch (action.type) {
    case ACTION_TYPE.SET_ADS:
      return {
        ...state,
        breadcrumbs: action.payload.breadcrumbs,
        recommendations: action.payload.recommendations,
        paging: action.payload.paging ?? state.paging,
        ads: action.payload.ads ?? null,
        spotlights: action.payload.spotlights ?? null,
        seoData: action.payload.seoData ?? state.seoData,
        dfp: action.payload.dfp,
        gtm: action.payload.gtm,
        faq: action.payload.faq,
        isLoading: false,
      };
    case ACTION_TYPE.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export interface ISearchPageState {
  paging: PagingType;
  breadcrumbs: Array<SearchPageBreadcrumbs>;
  recommendations?: Array<ISearchPageAds>;
  ads: Array<Ad> | null;
  spotlights: Array<Ad> | null;
  seoData: ISearchPageSeoData;
  dfp: IDFP;
  gtm: GTMEvent;
  isLoading: boolean;
  faq?: any;
}

export interface ISearchPageDispatch {
  setAds: (args: SetAdsArgs) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const defaultState: ISearchPageState = {
  paging: {
    totalPages: 0,
    currentPage: 0,
    nextFrom: 0,
    previousFrom: 0,
    displayingFrom: 0,
    displayingTo: 0,
    totalResults: 0,
    pageSize: 0,
  },
  breadcrumbs: [],
  recommendations: [],
  isLoading: false,
  seoData: {},
  dfp: {
    area: [],
    section: [],
    user_id: [],
    vertical: [],
    words: [],
  },
  gtm: {},
  ads: null,
  spotlights: null,
};

const useSearchPage = (
  breadcrumbs: SearchPageBreadcrumbs[],
  recommendations: ISearchPageAds[],
  seoData: ISearchPageSeoData,
  dfp: IDFP,
  gtm: GTMEvent,
  paging: PagingType,
  ads: Array<Ad> | null,
  spotlights: Array<Ad> | null,
  faq?: Faq | null,
) => {
  const [state, dispatch] = useReducer(searchReducer, {
    paging,
    breadcrumbs,
    recommendations,
    ads,
    dfp,
    gtm,
    seoData,
    isLoading: false,
    spotlights,
    faq,
  });

  const setIsLoading = (isLoading: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_IS_LOADING,
      payload: isLoading,
    });

  const setAds = ({
    ads,
    spotlights,
    seoData,
    dfp,
    gtm,
    breadcrumbs,
    recommendations,
    paging,
    faq,
  }: SetAdsArgs) =>
    dispatch({
      type: ACTION_TYPE.SET_ADS,
      payload: {
        ads,
        spotlights,
        seoData,
        dfp,
        gtm,
        breadcrumbs,
        recommendations,
        paging,
        faq,
      },
    });

  return {
    state,
    dispatch: {
      setAds,
      setIsLoading,
    },
  };
};

export { useSearchPage };
