import styled from 'styled-components';

import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';

import { media } from 'helpers/breakpoints';

const StyledCheckbox = styled(Checkbox)`
  padding-top: ${({ theme }) => theme.spacing.S8};

  ${media.medium} {
    padding-top: ${({ theme }) => theme.spacing.M16};
  }
`;

const LabelContent = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.S8};

  ${media.large} {
    gap: ${({ theme }) => theme.spacing.S4};
  }
`;

export { StyledCheckbox, LabelContent };
