import { capitalizeFirstLetter } from 'helpers/string';

import type { Faq as FaqProps, FaqItemProps } from './Faq.typed';

import {
  SFaq,
  SFaqTitle,
  SFaqContainer,
  SFaqItemQuestion,
  SFaqItemAnswer,
  FaqItemContainer,
} from 'components/SearchPage/components/Faq/Faq.styled';
import { formatIndefiniteArticle } from 'helpers/formatting';

const FaqItem = ({ question, answer }: FaqItemProps) => {
  return (
    <FaqItemContainer
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <SFaqItemQuestion itemProp="name">{question}</SFaqItemQuestion>
      <SFaqItemAnswer
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <div itemProp="text">{answer}</div>
      </SFaqItemAnswer>
    </FaqItemContainer>
  );
};

const Faq = (props: FaqProps) => {
  const { avgPrice, avgMileage, topYear, topFuelType, topColor, makeModel } =
    props;

  const vehicleTerm = makeModel ?? 'car';
  const formattedIndefiniteArticle = formatIndefiniteArticle(vehicleTerm[0]);

  return (
    <SFaq data-testid="faq">
      <div className="row small-collapse medium-uncollapse">
        <div className="small-12">
          <header>
            <SFaqTitle>About Cars in Ireland on DoneDeal</SFaqTitle>
          </header>
          <SFaqContainer itemScope itemType="https://schema.org/FAQPage">
            <FaqItem
              question={`What's the average price of ${formattedIndefiniteArticle} ${vehicleTerm}?`}
              answer={
                <>
                  The average price of {formattedIndefiniteArticle}{' '}
                  {vehicleTerm}
                  &nbsp;in Ireland is&nbsp;
                  <b>{avgPrice}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the average mileage of ${formattedIndefiniteArticle} ${vehicleTerm}?`}
              answer={
                <>
                  The average mileage of {formattedIndefiniteArticle}{' '}
                  {vehicleTerm}
                  &nbsp;in Ireland is&nbsp;
                  <b>{capitalizeFirstLetter(avgMileage)}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the most popular ${vehicleTerm} year?`}
              answer={
                <>
                  The most popular {vehicleTerm} year in Ireland is&nbsp;
                  <b>{topYear}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the most popular ${makeModel ?? ''} fuel type?`}
              answer={
                <>
                  The most popular {makeModel ?? ''} fuel type in Ireland
                  is&nbsp;
                  <b>{capitalizeFirstLetter(topFuelType)}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the most popular ${vehicleTerm} colour?`}
              answer={
                <>
                  The most popular {vehicleTerm} colour in Ireland is&nbsp;
                  <b>{capitalizeFirstLetter(topColor)}</b>
                </>
              }
            />
          </SFaqContainer>
        </div>
      </div>
    </SFaq>
  );
};

export { Faq };
