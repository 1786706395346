import { useState } from 'react';
import { useRouter } from 'next/router';

import type { UseSectionBrowserProps } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.hook.typed';

import { asString } from 'utils/query-parameters';
import { SECTION_DISPLAY_LIMIT } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.constants';

const useSectionBrowser = (props: UseSectionBrowserProps) => {
  const { sections, collapsible, currentSection } = props;

  const [collapsed, setCollapsed] = useState(true);

  const filteredSubsections = sections.filter(
    (section) => section.searchMatches && section.searchMatches > 0,
  );
  const showSubsections = filteredSubsections.length > 0;
  const shouldCollapse =
    collapsible && filteredSubsections.length > SECTION_DISPLAY_LIMIT;

  const { asPath } = useRouter();

  const getLink = (sectionName: string) => {
    const path = asString(asPath);

    /*
     * When the user selects a section from the section browser, we should retain the applied filters.
     * In order to do this, we replace the name of the section found in the URL with the relevant section name.
     * We drop the pagination value (start) to bring the user to the first page of results, like any other filter.
     */
    return path
      ? path
          .replace(currentSection, sectionName)
          .replace(/[?|&](start=)\d+/, '')
      : '';
  };

  return {
    collapsed,
    setCollapsed,
    shouldCollapse,
    getLink,
    showSubsections,
    filteredSubsections,
  };
};

export { useSectionBrowser };
