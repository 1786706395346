import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useRouter } from 'next/router';

const useCertifiedTrustedDealerCarsCheckbox = () => {
  const {
    query: { verifications },
  } = useRouter();

  const { updateURL } = useURLStateManagement();

  const CERTIFIED_TRUSTED_DEALER_VALUE = 'trustedDealer';

  const checked = Array.isArray(verifications)
    ? verifications.includes(CERTIFIED_TRUSTED_DEALER_VALUE)
    : verifications === CERTIFIED_TRUSTED_DEALER_VALUE;

  const handleSelectedVerifications = (
    verifications: Array<string> | string,
  ) => {
    if (Array.isArray(verifications)) {
      const updatedVerifications = verifications.includes(
        CERTIFIED_TRUSTED_DEALER_VALUE,
      )
        ? verifications.filter(
            (item) => item !== CERTIFIED_TRUSTED_DEALER_VALUE,
          )
        : [...verifications, CERTIFIED_TRUSTED_DEALER_VALUE];

      const formattedUpdatedVerifications =
        updatedVerifications.length > 1
          ? updatedVerifications
          : updatedVerifications[0];

      updateURL({
        queryParams: { verifications: formattedUpdatedVerifications },
      });
      return;
    }
    const updatedVerifications =
      verifications === CERTIFIED_TRUSTED_DEALER_VALUE
        ? ''
        : [verifications, CERTIFIED_TRUSTED_DEALER_VALUE];

    updateURL({
      queryParams: { verifications: updatedVerifications },
    });
  };

  const onChange = () => {
    if (verifications) {
      handleSelectedVerifications(verifications);
      return;
    }
    updateURL({
      queryParams: { verifications: CERTIFIED_TRUSTED_DEALER_VALUE },
    });
  };

  return {
    checked,
    onChange,
  };
};

export { useCertifiedTrustedDealerCarsCheckbox };
