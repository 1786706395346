import { createContext, useContext } from 'react';
import {
  MakeModelContextProps,
  MakeModelProviderProps,
} from 'components/SearchPage/features/makeModel/MakeModel.typed';

import { useMakeModelReducer } from 'components/SearchPage/features/makeModel/MakeModelReducer';

const defaultValue = {
  isExpanded: false,
  setIsExpanded: () => null,
  isMakeModelBottomSheetOpen: false,
  setIsMakeModelBottomSheetOpen: () => null,
  handleClose: () => null,
  clearMakeModels: () => null,
  closeDisplay: () => null,
  makeModels: undefined,
  modelOptions: undefined,
  modelItems: undefined,
  addModelOptions: () => null,
  setModelItems: () => null,
  setMakeModels: () => null,
};

const MakeModelContext = createContext<MakeModelContextProps>(defaultValue);

const MakeModelProvider = (props: MakeModelProviderProps) => {
  const { initialMakeModels, children } = props;
  const values = useMakeModelReducer({
    initialMakeModels,
  });

  return (
    <MakeModelContext.Provider value={values}>
      {children}
    </MakeModelContext.Provider>
  );
};

const useMakeModelContext = () => useContext(MakeModelContext);

export { MakeModelProvider, useMakeModelContext };
