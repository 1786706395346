import { useState } from 'react';
import { useRouter } from 'next/router';

import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useLocationContext } from 'features/location/Location.context';
import { useFiltersFormatting } from 'features/filters/FiltersFormatting.hook';

import type {
  ISearchPageFilter,
  ISortField,
} from 'api/types/searchPageApiTypes';
import type {
  FilterDispatch,
  FilterState,
} from 'features/filters/Filters.typed';

import {
  formatPathParameters,
  getFilterPathParameterData,
} from 'helpers/seo/searchParams';
import { formatMakeAndModelQueriesForFilters } from 'components/SearchPage/helpers/formatting';

const useFilters = (
  initialFiltersData: ISearchPageFilter[],
  initialSortFields: ISortField[],
) => {
  const { query } = useRouter();
  // TODO: create reducer
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filtersData, setFiltersData] =
    useState<ISearchPageFilter[]>(initialFiltersData);
  const [sortFields, setSortFields] = useState<ISortField[]>(initialSortFields);

  const { getFilterCopy } = useFiltersFormatting();
  const { locationDisplay } = useLocationContext();
  const { resetURL } = useURLStateManagement();

  const { section, make: makeQuery, radius, countyTown } = query;

  const { makePathParameter, modelPathParameter, SEOFilterValue } =
    formatPathParameters(section);

  const displayRadius = countyTown && radius ? ` (+${radius}km)` : '';
  const displayModel = modelPathParameter ? ` ${modelPathParameter}` : '';

  const locationCopy = locationDisplay
    ? `${locationDisplay}${displayRadius}`
    : undefined;

  const makeModelCopy = makePathParameter
    ? `${makePathParameter}${displayModel}`
    : formatMakeAndModelQueriesForFilters(makeQuery);

  const { levelOneReset } = useLocationContext();

  const reset = () => {
    resetURL();
    levelOneReset();
  };

  const displayFilterAndRangeText = (filter: ISearchPageFilter) => {
    const { filterPathParameter, formattedFilterName } =
      getFilterPathParameterData([filter], SEOFilterValue);

    const filterQuery =
      filterPathParameter && formattedFilterName === filter.name
        ? filterPathParameter
        : query;

    return getFilterCopy(filter, filterQuery);
  };

  const displayText = (filter: ISearchPageFilter) => {
    switch (filter.name) {
      case 'location':
        return locationCopy;
      case 'makeModel':
        return makeModelCopy;
      case 'make':
        return makePathParameter;
      default:
        return displayFilterAndRangeText(filter);
    }
  };

  const state: FilterState = {
    filtersData,
    sortFields,
    isFilterModalOpen,
  };

  const dispatch: FilterDispatch = {
    setIsFilterModalOpen,
    reset,
    displayText,
    setFiltersData,
    setSortFields,
  };

  return {
    state,
    dispatch,
  };
};

export { useFilters };
