import { ShieldCheckmark2Ic } from '@dsch/dd-icons';

import { useCertifiedTrustedDealerCarsCheckbox } from './CertifiedTrustedDealerCarsCheckbox.hook';

import { theme } from 'helpers/Theme';

import {
  StyledCheckbox,
  LabelContent,
} from './CertifiedTrustedDealerCarsCheckbox.styled';

/*
 *  This filter should be considered temporary.
 *  Its performance will be measured to determine whether or not it becomes a permanent filter.
 */
const CertifiedTustedDealerCarsCheckbox = () => {
  const { checked, onChange } = useCertifiedTrustedDealerCarsCheckbox();

  return (
    <StyledCheckbox
      label={
        <LabelContent>
          Certified Trusted Dealer only
          <ShieldCheckmark2Ic
            height={20}
            width={20}
            color={theme.colors.GREEN}
          />
        </LabelContent>
      }
      checked={checked}
      onChange={onChange}
      name="certifiedTrustedDealerCars"
      willUseSubText={false}
      data-tracking="certified-trusted-dealer-cars-filter"
    />
  );
};

export { CertifiedTustedDealerCarsCheckbox };
