import { useEffect } from 'react';
import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import axios from 'axios';
import StatusCode from 'status-code-enum';

import { searchPageApi } from 'api/searchPageApi';
import { sellerApi } from 'api/sellerApi';

import { DefaultLayout } from 'components/Layouts';
import { SearchPage } from 'components/SearchPage/SearchPage';
import { SearchPageProvider } from 'components/SearchPage/context/SearchPageContext';
import { LocationProvider } from 'features/location/Location.context';
import { FilterProvider } from 'features/filters/Filters.context';

import {
  getSectionParams,
  generateGetAdsRequestParams,
  formatFaqSchema,
} from 'helpers/seo/searchParams';
import {
  createHiddenFilters,
  mapCountyList,
  mapGeoFilter,
  mapGeoFilterRequest,
  mapLocationDisplay,
  mapRangesRequest,
  mapSelectedCounyAndCoordinatesAndLocationDisplay,
} from 'features/filters/Filters.mapper';
import { mapServerMakeModels } from 'components/SearchPage/features/makeModel/MakeModel.map';

import type {
  IQueryParams,
  ResponseMakeModelType,
  ResponseModelFilter,
  Notifications,
} from 'types';
import type { FeaturedDealer } from 'api/types/adviewApiTypes';
import type { GetFiltersWithCountRequest } from 'api/types/searchPageApiTypes';
import type { ViewType } from 'components/SearchPage/SearchPage.typed';
import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import type { Coordinates } from 'features/location/Location.typed';
import type { ISeller } from 'api/types/sellerApiTypes';
import type {
  ISearchPageFilter,
  ISearchPageAds,
  SearchPageBreadcrumbs,
  ISearchPagePaging,
  IDFP,
  IGetSection,
  ISortField,
  ISearchPageSeoData,
  Ad,
} from 'api/types/searchPageApiTypes';

import MetricService from 'services/MetricService';
import { getServerCookie } from 'utils';
import { cookies } from 'constants/cookies';
import { logServerError } from 'helpers/logger';
import { PAGE } from 'helpers/pages';
import { getVerticalFromBreadcrumbs } from 'helpers/verticals';
import { updateSiteFocusCookie } from 'helpers/updateSiteFocusCookie';
import { generateRecaptchaToken } from 'helpers/reCaptcha';
import { GTMEvent } from 'services/gtmService';

import {
  UPDATED_LOCATIONS as countyListFallback,
  RADIUS_OPTIONS as radiusListFallback,
} from 'features/location/Location.constants';
import { Faq } from 'components/SearchPage/components/Faq/Faq.typed';

interface ISectionProps {
  faq: Faq | null;
  ads: Array<Ad> | null;
  spotlights: Array<Ad> | null;
  paging: ISearchPagePaging;
  filtersData: ISearchPageFilter[];
  sortFields: ISortField[];
  sectionsData: IGetSection[];
  breadcrumbs: SearchPageBreadcrumbs[];
  notificationData?: Notifications;
  notFound: boolean;
  saved: boolean;
  seoData: ISearchPageSeoData;
  recommendations: ISearchPageAds[];
  trader: ISeller | null;
  promoSlot?: FeaturedDealer | null;
  dfp: IDFP;
  gtm: GTMEvent;
  viewType: ViewType;
  sectionDisplayName: string;
  parentName: string | null;
  parentDisplayName: string | null;
  searchMatches: number;
  selectedCounty: ListItem | null;
  radius: string | null;
  locationDisplay: string;
  coordinates: Coordinates;
  countyTown: string | null;
  countyList: ListItem[];
  radiusList: {
    label: string;
    value: string;
  }[];
  initialMakeModels: Array<{ make: string; model: Array<string> | null }>;
}

interface ServerSideProps extends ISectionProps {
  domain?: string;
}

function Section(props: ServerSideProps) {
  const {
    domain = '',
    breadcrumbs,
    dfp,
    gtm,
    sectionsData,
    ads,
    spotlights,
    seoData,
    recommendations,
    filtersData,
    paging,
    notificationData,
    notFound,
    trader,
    promoSlot,
    saved,
    viewType,
    faq,
    sectionDisplayName,
    parentName,
    parentDisplayName,
    searchMatches,
    selectedCounty,
    radius,
    locationDisplay,
    coordinates,
    countyTown,
    countyList,
    radiusList,
    sortFields,
    initialMakeModels,
  } = props;
  const vertical = getVerticalFromBreadcrumbs(breadcrumbs);
  updateSiteFocusCookie(vertical, domain);

  useEffect(() => {
    generateRecaptchaToken('search_page');
  }, []);

  return (
    <DefaultLayout
      domain={domain}
      notificationData={notificationData}
      vertical={vertical}
    >
      <SearchPageProvider
        paging={paging}
        viewType={viewType}
        saved={saved}
        baseUrl={domain}
        initialBreadcrumbs={breadcrumbs}
        initialRecommendations={recommendations}
        seoData={seoData}
        dfp={dfp}
        gtm={gtm}
        ads={ads}
        spotlights={spotlights}
        faq={faq}
      >
        <LocationProvider
          initialSelectedCounty={selectedCounty}
          initialRadius={radius}
          initialLocationDisplay={locationDisplay}
          initialCoordinates={coordinates}
          initialCountyTown={countyTown}
          countyList={countyList}
          radiusList={radiusList}
          page={PAGE.SEARCH}
        >
          <FilterProvider sortFields={sortFields} filtersData={filtersData}>
            <SearchPage
              initialValues={{
                sectionsData,
                sectionDisplayName,
                parentName,
                parentDisplayName,
                searchMatches,
                initialMakeModels,
              }}
              notFound={notFound}
              trader={trader}
              promoSlot={promoSlot}
            />
          </FilterProvider>
        </LocationProvider>
      </SearchPageProvider>
    </DefaultLayout>
  );
}

const isEngine = (value: string) =>
  value.length === 3 && value.indexOf('.') == 1;
const mapEngine = (engineValue: string) =>
  isEngine(engineValue) ? `${engineValue.replace('.', '')}00` : engineValue;

/**
 * Temp mapper until we fully migrate search
 * @param queryParams
 */
export function mapLegacyQueryParams(queryParams: IQueryParams): IQueryParams {
  const newQueryParams = { ...queryParams };
  const keys = Object.keys(newQueryParams);

  for (const key of keys) {
    switch (key) {
      case 'engine_from': {
        const engineFrom = newQueryParams.engine_from as string;
        if (engineFrom) {
          newQueryParams.engine_from = mapEngine(engineFrom);
        }
        break;
      }
      case 'engine_to': {
        const engineTo = newQueryParams.engine_to as string;
        if (engineTo) {
          newQueryParams.engine_to = mapEngine(engineTo);
        }
        break;
      }
      case 'cash-offer': {
        newQueryParams.cashOffer = newQueryParams['cash-offer'];
        delete newQueryParams['cash-offer'];
        break;
      }
      case 'car-finance': {
        newQueryParams.carFinance = newQueryParams['car-finance'];

        if (newQueryParams.carFinance === 'yes') {
          newQueryParams.carFinance = 'true';
        }

        delete newQueryParams['car-finance'];
        break;
      }
      case 'source': {
        if (newQueryParams.source === 'trade') {
          newQueryParams.sellerType = 'pro';
        } else {
          newQueryParams.sellerType = newQueryParams.source;
        }
        delete newQueryParams.source;
        break;
      }
      case 'priceType': {
        if (newQueryParams.priceType === 'Pound') {
          if (newQueryParams.price_from) {
            newQueryParams.sterlingPrice_from = newQueryParams.price_from;
            delete newQueryParams.price_from;
          }
          if (newQueryParams.price_to) {
            newQueryParams.sterlingPrice_to = newQueryParams.price_to;
            delete newQueryParams.price_to;
          }
        }
        break;
      }
      case 'containsValidPrice': {
        if (newQueryParams.containsValidPrice !== 'true') {
          delete newQueryParams['containsValidPrice'];
        }

        break;
      }
    }
  }

  return newQueryParams;
}

export const getMakeModelFilters = async (
  section?: string,
  makeModelFilter?: ISearchPageFilter,
  makeModelParams?: ResponseMakeModelType,
  dealerId: string | null = null,
) => {
  let makeModelFiltersResponse: ResponseModelFilter = [];
  if (makeModelParams && makeModelFilter && section) {
    for (const makeModelParam of makeModelParams) {
      const selectedMakeFilter =
        (makeModelFilter.values &&
          makeModelFilter.values[0].all?.find(
            (valueItem) => valueItem.displayName === makeModelParam.make,
          )) ||
        [];
      if (selectedMakeFilter && !!makeModelParam.model) {
        const allModels = await searchPageApi.getModelByMake(
          section,
          makeModelParam.make,
          { accept: 'application/json' },
          dealerId,
        );
        allModels.data &&
          !makeModelFiltersResponse.find(
            (item) => item.makeName === makeModelParam.make,
          ) &&
          makeModelFiltersResponse.push({
            makeName: makeModelParam.make,
            ...allModels.data,
          });
      }
    }
  }
  return makeModelFiltersResponse;
};

export const getServerSideProps: GetServerSideProps<
  any,
  { section: string[] }
> = async (ctx): Promise<GetServerSidePropsResult<ServerSideProps>> => {
  // Temp map of query params to accommodate legacy URL
  const queryParams = mapLegacyQueryParams(ctx.query);
  const section = ctx.params?.section[0] || 'all';
  const keywordSearchTerms = queryParams.words
    ? Array.isArray(queryParams.words)
      ? queryParams.words[0]
      : queryParams.words
    : null;
  const notFound = typeof queryParams.notFound !== 'undefined';
  const saved = typeof queryParams.saved !== 'undefined';
  let trader: ISeller | null = null;
  const countyParam = queryParams.area
    ? Array.isArray(queryParams.area)
      ? queryParams.area
      : [queryParams.area]
    : null;
  const countyTownParam = queryParams.countyTown
    ? Array.isArray(queryParams.countyTown)
      ? queryParams.countyTown[0]
      : queryParams.countyTown
    : null;
  const radiusParam = queryParams.radius
    ? Array.isArray(queryParams.radius)
      ? queryParams.radius[0]
      : queryParams.radius
    : null;
  const makeParamValue = queryParams.make;

  // pull out user id
  const userId = queryParams.userId;
  try {
    const filters = await searchPageApi.getFilters(section);
    const sortFields = filters.data.sortFields;
    const filterData = [
      ...filters.data.showByDefault,
      ...createHiddenFilters(),
    ];

    const {
      isMakeModel,
      sectionQueryParams,
      makeModelParams,
      countyList,
      radiusList,
    } = getSectionParams(filterData, queryParams);

    if (userId) {
      const firstUser = Array.isArray(userId) ? userId[0] : userId;
      const { data } = await sellerApi.getAdvertiserProfile(firstUser);
      if (data.seller?.traderName) {
        trader = data.seller;
      }
    }

    const getAdsRequestParams = generateGetAdsRequestParams(
      section,
      {
        ...queryParams,
        ...sectionQueryParams,
      },
      filterData,
      userId,
    );

    const { selectedCounty, coordinates, locationDisplay } =
      await mapSelectedCounyAndCoordinatesAndLocationDisplay({
        sectionQueryParams,
        counties: countyList,
        countyParam,
        countyTownParam,
      });

    const locationData = mapGeoFilter({
      radius: radiusParam,
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      county: countyParam,
      countyTown: countyTownParam,
    });

    const geoFilter =
      locationData && countyTownParam ? locationData.geoFilter : undefined;

    // Only needed for initial API request.
    // DO NOT pass this to the client.
    const mappedGetAdsRequestParams = {
      geoFilter: mapGeoFilterRequest(geoFilter),
      ...getAdsRequestParams,
      ...(isMakeModel && {
        makeModelFilters: makeModelParams,
      }),
      ranges: getAdsRequestParams.ranges
        ? mapRangesRequest(getAdsRequestParams.ranges)
        : [],
    };

    const cookie = ctx.req.headers.cookie;

    const {
      data: {
        ads,
        spotlights,
        seoFaqSchema,
        paging,
        recommendations,
        dataTargetingProps,
        breadcrumbs,
        seoData,
        promoSlot,
      },
      headers,
    } = await searchPageApi.getAdsV2(mappedGetAdsRequestParams, {
      ...(cookie ? { cookie } : {}),
    });

    const unreadhistorychecks = headers.unreadhistorychecks;
    const unreadmessages = headers.unreadmessages;
    const totalunread = headers.totalunread;

    const sections = await searchPageApi.getSections(section, {
      ...mappedGetAdsRequestParams,
      terms: keywordSearchTerms,
    });

    const filtersWithCountRequest: GetFiltersWithCountRequest = {
      section,
      headers: {},
      payload: {
        ...mappedGetAdsRequestParams,
        terms: keywordSearchTerms,
      },
    };

    const filtersWithCount = await searchPageApi.getFiltersWithCount(
      filtersWithCountRequest,
    );

    const filterDataWithCount = [
      ...filtersWithCount.data.showByDefault,
      ...createHiddenFilters(),
    ];

    const viewType = getServerCookie(cookies.VIEW_TYPE, ctx, false) || 'list';

    const legacyLocationDisplay = mapLocationDisplay(countyParam);

    const mappedCountyList: ListItem[] | null = countyList
      ? mapCountyList(countyParam, countyList)
      : null;

    const faq = seoFaqSchema?.faqSchema
      ? formatFaqSchema(seoFaqSchema?.faqSchema, ctx.params?.section)
      : null;

    if (mappedGetAdsRequestParams?.paging?.from < 0) {
      console.warn(
        logServerError(PAGE.SEARCH, {
          message: 'Negative pagination value',
          details: {
            start: mappedGetAdsRequestParams?.paging?.from,
            referer: ctx.req.headers.referer,
            page: ctx.req.url,
          },
        }),
      );
    }

    const [_currentSection, make, model] = ctx.params?.section ?? [];

    const initialMakeModels = mapServerMakeModels(make, model, makeParamValue);

    return {
      props: {
        faq,
        paging,
        filtersData: filterDataWithCount || [],
        sortFields: sortFields || [],
        ads: ads ?? null,
        spotlights: spotlights ?? null,
        breadcrumbs: breadcrumbs || [],
        sectionsData: sections.data.subSections || [],
        seoData: seoData || { internalLinks: [] },
        recommendations: recommendations || [],
        saved: saved || false,
        trader,
        promoSlot: promoSlot ?? null,
        dfp: dataTargetingProps?.dfp || {},
        gtm: dataTargetingProps?.gtm || {},
        notificationData: {
          totalUnread: totalunread ?? null,
          unreadHistoryChecks: unreadhistorychecks ?? null,
          unreadMessages: unreadmessages ?? null,
        },
        notFound,
        viewType,
        sectionDisplayName: sections.data.displayName,
        parentName: sections.data.parentName || null,
        parentDisplayName: sections.data.parentDisplayName || null,
        searchMatches: sections.data.searchMatches || 0,
        selectedCounty: selectedCounty ?? null,
        radius: radiusParam,
        locationDisplay: legacyLocationDisplay || locationDisplay,
        coordinates,
        countyTown: countyTownParam,
        countyList: mappedCountyList || countyListFallback,
        radiusList: radiusList || radiusListFallback,
        initialMakeModels: initialMakeModels ?? [],
      },
    };
  } catch (error) {
    console.error(logServerError(PAGE.SEARCH, error));
    if (axios.isAxiosError(error)) {
      const metricService = MetricService.get();
      metricService.incrementRequestErrorCount('SEARCH', {
        statusCode: error.response?.status,
      });
    }

    if (error.code === 'ECONNABORTED') {
      // Timeout
      ctx.res.statusCode = StatusCode.ServerErrorGatewayTimeout;
    } else if (error?.response?.status === StatusCode.ClientErrorBadRequest) {
      // Bad request
      ctx.res.statusCode = StatusCode.ClientErrorBadRequest;
    } else {
      // Signal that there is an error
      ctx.res.statusCode = StatusCode.ServerErrorInternal;
    }

    return {
      props: {
        paging: {
          pageSize: 0,
          totalResults: 0,
          totalPages: 0,
          previousFrom: 0,
          nextFrom: 0,
          displayingTo: 0,
          displayingFrom: 0,
          currentPage: 0,
        },
        filtersData: [],
        sortFields: [],
        breadcrumbs: [],
        promoSlot: null,
        sectionsData: [],
        seoData: {
          internalLinks: [],
        },
        recommendations: [],
        faq: null,
        saved: false,
        trader: null,
        dfp: {
          vertical: [],
          section: [],
          words: [],
          area: [],
          user_id: [],
        },
        gtm: {},
        notificationData: {
          totalUnread: 0,
          unreadHistoryChecks: 0,
          unreadMessages: 0,
        },
        notFound,
        viewType: 'list',
        sectionDisplayName: 'All',
        parentName: null,
        parentDisplayName: null,
        searchMatches: 0,
        selectedCounty: null,
        radius: null,
        locationDisplay: '',
        coordinates: {
          latitude: '',
          longitude: '',
        },
        countyTown: null,
        countyList: countyListFallback,
        radiusList: radiusListFallback,
        initialMakeModels: [],
        ads: null,
        spotlights: null,
      },
    };
  }
};

export default Section;
