import type { ReactNode } from 'react';

import type { ListItem } from 'components/Toolkit/Inputs/CustomSelect';

import type { HeightVariant } from 'components/Toolkit/Inputs/types';

import type { DisplayContainerConfig } from 'components/Support/DisplayContainer/DisplayContainer';

export enum ACTION_TYPE {
  SET_IS_EXPANDED = 'SET_IS_EXPANDED',
  SET_IS_MAKE_MODEL_BOTTOM_SHEET_OPEN = 'SET_IS_MAKE_MODEL_BOTTOM_SHEET_OPEN',
  HANDLE_CLOSE = 'HANDLE_CLOSE',
  CLEAR_MAKE_MODELS = 'CLEAR_MAKE_MODELS',
  CLOSE_DISPLAY = 'CLOSE_DISPLAY',
  ADD_MODEL_OPTIONS = 'ADD_MODEL_OPTIONS',
  ADD_MODEL_ITEMS = 'ADD_MODEL_ITEMS',
  SET_MAKE_MODELS = 'SET_MAKE_MODELS',
}

export type MakeModel = {
  make: string;
  model?: Array<string>;
  isEditable?: boolean;
};

export type StateType = {
  isExpanded: boolean;
  isMakeModelBottomSheetOpen: boolean;
  makeModels?: Array<MakeModel>;
  modelOptions?: Array<Options>;
  modelItems?: Array<ListItem>;
};

type SetIsExpandedType = {
  type: ACTION_TYPE.SET_IS_EXPANDED;
  payload: boolean;
};

type SetIsMakeModelBottomSheetOpenType = {
  type: ACTION_TYPE.SET_IS_MAKE_MODEL_BOTTOM_SHEET_OPEN;
  payload: boolean;
};

type HandleCloseType = {
  type: ACTION_TYPE.HANDLE_CLOSE;
  payload: ListItem[];
};

type ClearMakeModelsType = {
  type: ACTION_TYPE.CLEAR_MAKE_MODELS;
  payload?: Array<ListItem>;
};

type CloseDisplayType = {
  type: ACTION_TYPE.CLOSE_DISPLAY;
};

type AddModelOptionsType = {
  type: ACTION_TYPE.ADD_MODEL_OPTIONS;
  payload?: Array<Options>;
};

type AddModelItemsType = {
  type: ACTION_TYPE.ADD_MODEL_ITEMS;
  payload?: Array<ListItem>;
};

type SetMakeModelsType = {
  type: ACTION_TYPE.SET_MAKE_MODELS;
  payload?: Array<MakeModel>;
};

export type ActionType =
  | CloseDisplayType
  | ClearMakeModelsType
  | HandleCloseType
  | SetIsMakeModelBottomSheetOpenType
  | SetIsExpandedType
  | AddModelOptionsType
  | AddModelItemsType
  | SetMakeModelsType;

export type MakeModelProps = {
  maxHeight?: string;
  maxTextContainerWidthMWeb?: string;
  maxTextContainerWidthWeb?: string;
  mWebConfig: DisplayContainerConfig;
  webConfig: DisplayContainerConfig;
  selectMake: (value: string) => void;
  selectModel: (make: string, model: string | Array<string>) => void;
  maximumAmountOfMakeModelGroupsToBeDisplayed: number;
  onAddSelection: () => void;
  makeOptions?: Array<Options>;
  heightVariant?: HeightVariant;
};

export type Options = {
  title: string | null;
  items: Array<ListItem>;
};

export type ContainerStyleProps = {
  displayInline?: boolean;
};

export type MakeModelProviderProps = {
  children: ReactNode;
} & UseMakeModelReducerProps;

export type UseMakeModelReducerProps = {
  initialMakeModels?: Array<MakeModel>;
};

export type MakeModelContextProps = {
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
  isMakeModelBottomSheetOpen: boolean;
  setIsMakeModelBottomSheetOpen: (isMakeModelBottomSheetOpen: boolean) => void;
  handleClose: (areas: ListItem[]) => void;
  clearMakeModels: () => void;
  closeDisplay: () => void;
  makeModels?: Array<MakeModel>;
  modelOptions?: Array<Options>;
  modelItems?: Array<ListItem>;
  addModelOptions: (modelOptions?: Array<Options>) => void;
  setModelItems: (modelItems?: Array<ListItem>) => void;
  setMakeModels: (makeModels?: Array<MakeModel>) => void;
};
